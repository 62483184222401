import React, { useEffect, useState } from "react";
import logo from "./volta_insite_logo_2024.png";
import LogoutButton from "./LogoutButton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Dropdown from "react-dropdown";
import { useSelector, useDispatch } from "react-redux";
import { voltageTypeActions } from "../store/voltageTypeSlice/volatgeTypeSlice";

import "../css/TopNav.css";

const voltageTypeOptions = [
    { label: "Line to Line", value: "L-L" },
    { label: "Line to Neutral", value: "L-N" },
];

export default function TopNav(props) {
    const { selectedVoltageType } = useSelector((state) => state.voltageType);
    const selectedEquipment = useSelector((state) => state.equipmentsPortfolio.selectedEquipment);
    const dispatch = useDispatch();

    const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
    const [alert, setAlert] = useState({
        text: "Feature not available for this Equipment",
        color: "primary",
        show: false,
    });

    useEffect(() => {
        if (selectedEquipment) {
            if (
                selectedEquipment.product_type === "SEL" ||
                selectedEquipment.eq_type_sub === "v1" ||
                selectedEquipment.eq_type === "dc" ||
                selectedEquipment.eq_type_sub === "v2"
            ) {
                if (selectedVoltageType.value !== "L-L") {
                    dispatch(voltageTypeActions.setVoltageType({ label: "Line to Line", value: "L-L" }));
                }
                setIsDropdownDisabled(true);
            } else if (selectedEquipment.eq_type_sub === "i1") {
                if (selectedVoltageType.value !== "L-N") {
                    dispatch(voltageTypeActions.setVoltageType({ label: "Line to Neutral", value: "L-N" }));
                }
                setIsDropdownDisabled(true);
            } else {
                setIsDropdownDisabled(false);
            }
        }
    }, [selectedEquipment]);

    return (
        <React.Fragment>
            <Row className="align-self-center  my-auto">
                <Col className="col-8">
                    <img src={logo} alt="logo" />
                </Col>
                <Col className="col-1 mt-4 voltageLabel">
                    <label>Voltage: </label>
                </Col>
                <Col className="col-2 mt-4 l-lDropdown">
                    <Dropdown
                        disabled={isDropdownDisabled}
                        onFocus={() => {
                            if (isDropdownDisabled) {
                                setAlert({ ...alert, show: true });
                                setTimeout(() => {
                                    setAlert({ ...alert, show: false });
                                }, 4000);
                            }
                        }}
                        options={voltageTypeOptions}
                        value={selectedVoltageType}
                        onChange={(val) => {
                            dispatch(voltageTypeActions.setVoltageType(val));
                        }}
                    />
                    <br></br>
                    <div className="dropdown-alert">
                        <Alert variant={alert.color} show={alert.show}>
                            {alert.text}
                        </Alert>
                    </div>
                </Col>

                <Col className="logoutbutton-ctn col-1">
                    <LogoutButton />
                </Col>
            </Row>
        </React.Fragment>
    );
}

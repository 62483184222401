import React from 'react';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { equipmentsPortfolioActions } from '../../../../store/equipmentsPortfolioSlice/equipmentPortfolioSlice';

const SelectDivision = () => {
	const { selectedCustomer, divisionList, selectedDivision, portfolio } = useSelector(
		(state) => state.equipmentsPortfolio
	);
	const dispatch = useDispatch();

	const onSelectDivision = (division) => {
		dispatch(equipmentsPortfolioActions.setSelectedDivision(division));
	};

	return (
		<React.Fragment>
			<Select options={divisionList} value={selectedDivision} onChange={(value) => onSelectDivision(value)} />
		</React.Fragment>
	);
};

export default SelectDivision;
import authAxios from '../axios/authAxios';
import portalAxios from '../axios/portalAxios'

class OverviewApi{

    static getFacilityInfo(accessToken, customerName) {
		return authAxios.get('/v1/overview/get_facility_info',{
			params:{
				customer_name: customerName
			},
			headers: { Authorization: `Bearer ${accessToken}` },
		})
	}

    static getAlerts(
        accessToken,
        facilities
    ){
        return authAxios.get('/v1/overview/alerts/', {
            params:{
                facilities: JSON.stringify(facilities)
            },
            headers: {
				Authorization: `Bearer ${accessToken}`,
			},
        })
    }

    static getAlertsContent(accessToken, key, image_keys) {
        
        let img_key = image_keys
        if (image_keys === undefined) {
             img_key = ''
        }
      
        return authAxios.get('/v1/overview/alert_content/',
            {
                params: {
                    body_s3_key: `${key}`,
                    img_s3_keys: `${img_key}`
                },
                headers: { Authorization: `Bearer ${accessToken}` }
            })
    }
}

export default OverviewApi
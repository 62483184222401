import React, { useState, useEffect, useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import NotificationsSettingsApi from '../../api/NotificationSettingsApi';
import Select from 'react-select';
import Cookies from 'js-cookie';

export default function NotificationSettings() {
	const selectedTab = useSelector((state) => state.tabStatus.selectedTab);
	const customer = useSelector((state) => state.equipmentsPortfolio.customerList[0].label);

	const [subscription, setSubscription] = useState({
		autoSubscribeAllFacilities: false,
		receiveConnectivityNotifications: false,
		receiveFaultLibraryNotifications: false,
	});
	const [subscriptionList, setSubscriptionList] = useState();
	const [subscribedList, setSubscribedList] = useState();
	const [subscriptionFrequency, setSubscriptionFrequency] = useState('DAILY');

	const [notificationAlert, setNotificationAlert] = useState({
		text: 'Click Save to Update Settings',
		show: false,
		color: 'primary',
	});
	const portalToken = Cookies.get('portalSession');

	useEffect(() => {
		NotificationsSettingsApi.getSubscriptions(portalToken)
			.then((response) => {
				setSubscription(response.data.data);

				let tempList = [];
				if (!response.data.data.autoSubscribeAllFacilities) {
					// Preparing data for drop-down
					for (const key in response.data.data.facilities) {
						response.data.data.facilities[key].label = response.data.data.facilities[key].name;
						response.data.data.facilities[key].value = response.data.data.facilities[key].name;
						if (response.data.data.facilities[key].subscription === true) {
							tempList.push(response.data.data.facilities[key]);
						}
					}
					setSubscribedList(tempList);
					setSubscriptionList(response.data.data.facilities);
					setSubscriptionFrequency(response.data.data.frequency);
				}
			})
			.catch((error) => console.error(error));
	}, []);

	useEffect(() => {
		if (selectedTab === 'Notifications') {
			NotificationsSettingsApi.getSubscriptions(portalToken)
				.then((response) => {
					setSubscription(response.data.data);

					let tempList = [];
					// Preparing data for drop-down
					if (!response.data.data.autoSubscribeAllFacilities) {
						// Preparing data for drop-down
						for (const key in response.data.data.facilities) {
							response.data.data.facilities[key].label = response.data.data.facilities[key].name;
							response.data.data.facilities[key].value = response.data.data.facilities[key].name;
							if (response.data.data.facilities[key].subscription === true) {
								tempList.push(response.data.data.facilities[key]);
							}
						}
						setSubscribedList(tempList);
						setSubscriptionList(response.data.data.facilities);
						setSubscriptionFrequency(response.data.data.frequency);
					}
				})
				.catch((error) => console.error(error));
		}
	}, [selectedTab]);

	const onFacilitySelect = (value) => {
		setSubscribedList(value);
		setNotificationAlert({ color: 'primary', text: 'Click Save to Update Settings', show: true });
	};

	const onSaveButtonClick = () => {
		let tempList = Object.assign({}, subscription);
		let indexList = [];
		let index = 0;
		for (const key in subscribedList) {
			index = tempList.facilities.findIndex((item) => item.name === subscribedList[key].name);
			indexList.push(index);
		}
		for (const i in tempList.facilities) {
			if (indexList.includes(parseInt(i)) === true) {
				tempList.facilities[i].subscription = true;
			} else {
				tempList.facilities[i].subscription = false;
			}
		}
		tempList.frequency = subscriptionFrequency;

		setSubscription(tempList);

		NotificationsSettingsApi.patchSubscriptions(portalToken, tempList)
			.then((response) => {
				setNotificationAlert({ color: 'success', text: 'Settings Saved', show: true });
			})
			.catch((error) => {
				console.log(error);
				setNotificationAlert({ color: 'primary', text: 'Click Save to Update Settings', show: true });
			});
	};

	const onReportFrequencyChange = (val) => {
		setSubscriptionFrequency(val.target.value);
		setNotificationAlert({ color: 'primary', text: 'Click Save to Update Settings', show: true });
	};

	return (
		<div>
			<Row className='mt-3'>
				<Col>
					<Card>
						<Card.Body>
							<Row>
								<Col className='col-4'>
									<Card.Header>
										<Row className='justify-content-md-center'>
											<Col>
												<h4>{customer} </h4>
											</Col>
										</Row>
										<Row className='justify-content-md-center'>
											<Col>
												<text className='supportEmail'>For Support Contact: </text>
												<a href='mailto:support@voltainsite.com' className='supportEmail'>
													support@voltainsite.com
												</a>
											</Col>
										</Row>
									</Card.Header>
								</Col>
							</Row>
							<Row>
									<Col className='col-4 mt-3'>
							{subscription.autoSubscribeAllFacilities ? (
								
										<Alert
											variant={notificationAlert.color}
											show={subscription.autoSubscribeAllFacilities}>
											User is Auto-Subscribed to notifications for all Facilities
										</Alert>
								
									) : <div className='placeholder-box'></div>}
									</Col>
									</Row>
							<Row>
								<Col>
									<label>Select For Facilities Notifications:</label>
								</Col>
							</Row>
							<Row>
								<Col className='col-4'>
									<Select
										isMulti
										onChange={onFacilitySelect}
										defaultValue={subscribedList}
										value={subscribedList}
										options={subscriptionList}
										isDisabled={subscription.autoSubscribeAllFacilities}
									/>
								</Col>
							</Row>

							<Row className='mt-4'>
								<Col>
									<label>Select Notifications Frequency:</label>
								</Col>
							</Row>

							<Row>
								<Col className='col-3'>
									<Form.Group as={Row} isDisabled={true}>
										<Col sm={10}>
											<Form.Check
												checked={subscriptionFrequency === 'DAILY'}
												onChange={onReportFrequencyChange}
												type='radio'
												value='DAILY'
												label='Daily'
												disabled={subscription.autoSubscribeAllFacilities}
											/>
											<Form.Check
												checked={subscriptionFrequency === 'WEEKLY'}
												onChange={onReportFrequencyChange}
												type='radio'
												label='Weekly'
												value='WEEKLY'
												disabled={subscription.autoSubscribeAllFacilities}
											/>
											<Form.Check
												checked={subscriptionFrequency === 'MONTHLY'}
												onChange={onReportFrequencyChange}
												type='radio'
												label='Monthly'
												value='MONTHLY'
												disabled={subscription.autoSubscribeAllFacilities}
											/>
										</Col>
									</Form.Group>
								</Col>
								<Col className='col-1 mt-3'>
									<Button
										onClick={onSaveButtonClick}
										disabled={subscription.autoSubscribeAllFacilities}
										className='plot_button'>
										Save
									</Button>
								</Col>
								{notificationAlert.show ? (
									<Col className='col-3 mt-5'>
										<Alert
											variant={notificationAlert.color}
											show={notificationAlert.show}
											onClose={() => setNotificationAlert({ ...notificationAlert, show: false })}
											dismissible>
											{' '}
											{notificationAlert.text}{' '}
										</Alert>
									</Col>
								) : null}
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	);
}

import authAxios from '../axios/authAxios'

class EventsAnalysisAPI {


	static getEventsList(
		accessToken,
		location_node_id,
		selectedEquipmentLabel,
		startDate,
		endDate,
		timezone,
		product_type,
		v_type
	) {
		return authAxios.get('/v1/event_analysis/get_events_list/', {
			params: {
				location_node_id: `${location_node_id}`,
				node_name: `${selectedEquipmentLabel}`,
				start_date: `${startDate}`,
				end_date: `${endDate}`,
				timezone: `${timezone}`,
				product_type: `${product_type}`,
				v_type: `${v_type}`
			},
			headers: { Authorization: `Bearer ${accessToken}` },
		});
	}

	static getEventsPlotData(
		accessToken,
		location_node_id,
		selectedEsa_value,
		product_type
	) {
		return authAxios.get('/v1/event_analysis/event_plot/', {
			params: {
				location_node_id: `${location_node_id}`,
				event_key: `${selectedEsa_value}`,
				product_type: `${product_type}`,
			},
			headers: { Authorization: `Bearer ${accessToken}` },
		});
	}

	static get_correlating_events_list(accessToken, selectedFacility, timestamp, tolerance, timezone) {
		return authAxios.get('/v1/event_analysis/correlate_event_timestamp/', {
			params: {
				facility_id: `${selectedFacility}`,
				timestamp: `${timestamp}`,
				tolerance: `${tolerance}`,
				timezone: `${timezone}`,
			},
			headers: { Authorization: `Bearer ${accessToken}` },
		});
	}


	static getEventsTrendingPlotData(accessToken, location_node_id, startDate, endDate, timezone) {
		return authAxios.get('/v1/event_analysis/events_trending_data/', {
			params: {
				location_node_id: `${location_node_id}`,
				start_date: `${startDate}`,
				end_date: `${endDate}`,
				timezone: `${timezone}`,
			},
			headers: { Authorization: `Bearer ${accessToken}` }, 
		});
	}

	static getSELAlarmsTrip(accessToken,location_node_id,startDate,endDate,timezone) {
		return authAxios.get('/v1/event_analysis/sel_alarms_trips/', {
			params: {
				location_node_id: `${location_node_id}`,
				start_date: `${startDate}`,
				end_date: `${endDate}`,
				timezone: `${timezone}`,
			},
			headers: { Authorization: `Bearer ${accessToken}` },
		});
	}
}



export default EventsAnalysisAPI;

import Dashboard from "./routes/Dashboard";
import Login from "./routes/Login";
import React, { useState, useEffect } from "react";
import { Redirect, BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "./axios/axios";
import jwt_decode from "jwt-decode";
import ErrorBoundaries from "./components/ErrorBoundaries/ErrorBoundaries";

function App() {
    const [token, setToken] = useState(Cookies.get("Session"));

    let tokenDuration = new Date().getTime();
    if (token !== undefined) {
        tokenDuration = new Date(jwt_decode(token).exp * 1000).getTime();
    }

    useEffect(() => {
        if (new Date().getTime() < tokenDuration) {
            axios
                .get("/v1/common/is_login/", { headers: { Authorization: `Bearer ${Cookies.get("Session")}` } })
                .then()
                .catch((err) => {
                    Cookies.remove("Session");
                    setToken();
                });
        }
    }, []);

    return (
        <ErrorBoundaries>
            <React.Fragment>
                <Router>
                    <Switch>
                        <Route exact path="/" render={(props) => <Login {...props} setToken={setToken} />} />
                        {token ? <Route exact path="/dash" component={Dashboard} /> : <Redirect to="/" />}
                        <Route path="*" component={() => " 404 Not Found"} />
                    </Switch>
                </Router>
            </React.Fragment>
        </ErrorBoundaries>
    );
}

export default App;

import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import DateRangePicker from '../../../../DatePickers/DateRangePicker/DateRangePicker';
import Select from 'react-select';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';

import TrendingAnalysisAPI from '../../../../../api/TrendingAnalysisApi';

const plotParametersAC= [
	{ label: 'Voltage', value: 'Voltage' },
	{ label: 'Current', value: 'Current' },
	{ label: 'Line Frequency', value: 'Line Frequency' },
	{ label: 'Power Factor', value: 'Power Factor' },
	{ label: 'Voltage Imbalance', value: 'Voltage Imbalance' },
	{ label: 'Current Imbalance', value: 'Current Imbalance' },
	{ label: 'Voltage THD', value: 'Voltage THD' },
	{ label: 'Current THD', value: 'Current THD' },
	{
		label: 'V-Peaks',
		value: 'V-Peaks',
	},
	{ label: 'HP', value: 'HP' },
];

const plotParametersDC= [
	{ label: 'Voltage', value: 'Voltage' },
	{ label: 'Current', value: 'Current' },
	{
		label: 'V-Peaks',
		value: 'V-Peaks',
	},
	{ label: 'HP', value: 'HP' },
];

const plotParametersUtilityMain = [
	{ label: 'Power Factor', value: 'Raw Power Factor' },
	{ label: 'Apparent Power (kVA)', value: 'Apparent Power (kVA)' },
	{ label: 'Reactive Power (kVAR)', value: 'Reactive Power (kVAR)' },
	{ label: 'Active Power (kW)', value: 'Active Power (kW)' },
	{ label: 'HP', value: 'RAW HP' },
]

//Add these parameters for primary DC and v2 nodes to query the field node
const fieldParameters=[
	{ label: 'Field Voltage', value: 'Field Voltage'},
	{ label: 'Field Current', value: 'Field Current'},
]

let plotParameters = plotParametersAC;

const formatDate = (date) => {
	return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
};

export default function FifteenMinutes({ tabData, setTabData, setPlotOrder }) {
	const token = Cookies.get('Session');

	// extarcting from redux slice
	const { selectedCustomer, selectedFacility, selectedEquipment, equipmentTimezone } = useSelector(
		(state) => state.equipmentsPortfolio
	);
	const selectedVoltageType = useSelector((state) => state.voltageType.selectedVoltageType);
	
	//Setting plot parameters for AC vs DC
	useEffect(() =>{
		if(selectedEquipment){
			if(selectedEquipment.eq_type === 'dc'){
				plotParameters = plotParametersDC;
				
				//Add Field parameters for primary nodes
				if(selectedEquipment.location_node_id.split('.')[1] == '0'){
					plotParameters = plotParameters.concat(fieldParameters)
				}
			}else{
				plotParameters = plotParametersAC;

				//Add field parameters for V2s
				if(selectedEquipment.eq_type_sub === 'v2'){
					plotParameters = plotParameters.concat(fieldParameters)
				}
			}

			if(selectedEquipment.equipment_type == 'UtilityMain'){
				plotParameters = plotParameters.filter((params) => {
					return params.label != 'HP' && params.label != 'Power Factor'
				}).concat(plotParametersUtilityMain)
			}

			//Checks to see if previously selected parameters are still avilable and removes those which aren't
			let params = selectedPlotParameters.filter((param) =>{
				if(plotParameters.some(el => el.label === param.label)){
					return true
				}else{
					return false
				}
			})
			setSelectedPlotParameters(params);
		}
	},[selectedEquipment])

	// 15 Minutes Tab Hooks
	const [minuteStartDate, setMinuteStartDate] = useState(new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 7));
	const [minuteEndDate, setMinuteEndDate] = useState(new Date());

	const [minuteTrendingPlotLoading, setMinuteTrendingPlotLoading] = useState(false);
	const [minuteAlert, setMinuteAlert] = useState({
		color: '',
		show: false,
		text: '',
	});

	const [selectedPlotParameters, setSelectedPlotParameters] = useState([plotParameters[0],plotParameters[1]]);

	const plottedEquipment = useRef();
	const plottedVoltageType = useRef();

	useEffect(() => {
		setMinuteAlert({ ...minuteAlert, show: false });
	}, [selectedCustomer, selectedFacility, selectedEquipment]);

	useEffect(() => {
		if (selectedEquipment) {
			const equipStartDate = new Date(parseInt(selectedEquipment.equipment_start_epoch * 1000));

			// Reset Date Pickers
			if (equipStartDate > minuteStartDate) {
				const tempDate = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 7);
				if (equipStartDate > tempDate) setMinuteStartDate(equipStartDate);
				else setMinuteStartDate(tempDate);

				setMinuteEndDate(new Date());
			}
		}
	}, [selectedEquipment]);

	useEffect(() => {
		if (selectedEquipment?.value == plottedEquipment.current && selectedVoltageType?.value != plottedVoltageType.current  && tabData.showPlot) {
			on15MinutePlotButtonClick();
		}
	}, [selectedVoltageType]);
	{
		/************************************ 15 Minutes Trending Functions **************************************************************/
	}

	const onMinuteStartDateChange = (date) => {
		setMinuteStartDate(date);
	};

	const onMinuteEndDateChange = (date) => {
		setMinuteEndDate(date);
	};

	const on15MinutePlotButtonClick = () => {
		if (!selectedCustomer) {
			setMinuteAlert({
				color: 'primary',
				show: true,
				text: 'Select a Customer',
			});
		} else if (!selectedFacility) {
			setMinuteAlert({
				color: 'primary',
				show: true,
				text: 'Select a Facility',
			});
		} else if (!selectedEquipment) {
			setMinuteAlert({
				color: 'primary',
				show: true,
				text: 'Select an Equipment ',
			});
		} else if (selectedPlotParameters === '') {
			setMinuteAlert({
				color: 'primary',
				show: true,
				text: 'Select at least one Parameter ',
			});
		} else {
			setTabData((prevData)=> {
				return{
					...prevData,
					showPlot:false
				}
			})
			setMinuteAlert({ ...minuteAlert, show: false });
			setMinuteTrendingPlotLoading(true);
			setPlotOrder({
				fifteenMinutes: 1,
				oneSecond: 2,
				realTimeMetering: 3,
			});
			setTabData((prevData) => {
				return{
					...prevData,
					plotData:{},
				    plotMetaData: {
					   selectedEquipment: selectedEquipment,
					   v_type: selectedVoltageType.value,
					   selectedParameters: selectedPlotParameters,
					   startDate: formatDate(minuteStartDate),
					   endDate: formatDate(minuteEndDate),
					   timezone: equipmentTimezone.value,
				    },
				    isPlotLoading: true,
				    showPlot: true
			   }
			});
			TrendingAnalysisAPI.getFifteenMinuteTrend(
				token,
				selectedEquipment.value,
				equipmentTimezone.value,
				formatDate(minuteStartDate),
				formatDate(minuteEndDate),
				selectedPlotParameters,
				selectedVoltageType.value,
				selectedEquipment.work_cycle
			).then((response) => {
				setMinuteTrendingPlotLoading(false);
				if (response.status === 204) {
					setMinuteAlert({
						color: 'primary',
						show: true,
						text: 'No Data Found',
					});
					setTabData(prevData => {
						return {
						...prevData,
						showPlot: false,
						isPlotLoading: false
						}
						
					});
				} else {
					plottedEquipment.current = selectedEquipment.value;
					plottedVoltageType.current = selectedVoltageType.value;
					setTabData(prevData => {
						return {
						...prevData,
						plotData: response.data.content,
						showPlot: true,
						isPlotLoading: false
						}
						
					});
					setPlotOrder({
						fifteenMinutes: 1,
						oneSecond: 2,
						realTimeMetering: 3,
					});
				}
			})
			.catch((error) => {
				setMinuteTrendingPlotLoading(false);
				setTabData(prevData => {
					return {
					...prevData,
					isPlotLoading: false
					}	
				});
				console.log(error);
			});
		}
	};

	return (
		<React.Fragment>
			<Row>
				<Col>
					<h2 className='margin-top-10'>15 Minute Trending</h2>{' '}
				</Col>
			</Row>
			<Row>
				<Col>
					<p>Select Date Range:</p>
				</Col>
			</Row>
			<Row>
				<Col className='minute-dateRange-input col-6'>
					<DateRangePicker
						startDate={minuteStartDate}
						endDate={minuteEndDate}
						onStartDateChange={onMinuteStartDateChange}
						onEndDateChange={onMinuteEndDateChange}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<br></br>
					<p>Select Parameters:</p>
					<Select
						isMulti
						isClearable
						defaultValue={[plotParameters[0], plotParameters[1]]}
						value={selectedPlotParameters}
						onChange={(value) => setSelectedPlotParameters(value)}
						options={plotParameters}
					/>
					<br></br>
				</Col>
			</Row>
			<Row>
				<Col className='col-2'>
					{minuteTrendingPlotLoading ? (
						<ReactLoading type='bars' height={50} width={50} color='#007bff' />
					) : (
						<Button variant='primary' onClick={on15MinutePlotButtonClick} className='plot_button'>
							Plot
						</Button>
					)}
				</Col>
				<Col className='col-4 trendingAlert'>
					<Alert
						variant={minuteAlert.color}
						show={minuteAlert.show}
						onClose={() => setMinuteAlert({ ...minuteAlert, show: false })}
						dismissible>
						{minuteAlert.text}
					</Alert>
				</Col>
			</Row>
		</React.Fragment>
	);
}

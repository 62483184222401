import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';

import TrendingAnalysisAPI from '../../../../../api/TrendingAnalysisApi';

export default function RealTimeMeteringTab({ setTabData, setPlotOrder }) {
	const portalToken = Cookies.get('portalSession');

	// extarcting from redux slice
	const { selectedCustomer, selectedFacility, selectedEquipment } = useSelector((state) => state.equipmentsPortfolio);

	const [realTimeAlert, setRealTimeAlert] = useState({
		color: '',
		show: false,
		text: '',
	});
	const [isPlotLoading, setIsPlotLoading] = useState(false);

	useEffect(() => {
		clearInterval(window.realTimeMeteringTimeId)
	},[selectedFacility, selectedEquipment])

	const onPlotButtonClick = () => {
		setRealTimeAlert({
			color: 'primary',
			show: false,
			text: '',
		});
		if (!selectedCustomer) {
			setRealTimeAlert({
				color: 'primary',
				show: true,
				text: 'Select a Customer',
			});
		} else if (!selectedFacility) {
			setRealTimeAlert({
				color: 'primary',
				show: true,
				text: 'Select a Facility',
			});
		} else if (!selectedEquipment) {
			setRealTimeAlert({
				color: 'primary',
				show: true,
				text: 'Select an Equipment ',
			});
		} else {
			clearInterval(window.realTimeMeteringTimeId);
			setIsPlotLoading(true);
			setTabData((prevState) => {
				return {
					...prevState,
					plotMetaData: {
						selectedEquipment: selectedEquipment,
					},
					showPlot: false,
					isPlotLoading: isPlotLoading
				};
			});
			setPlotOrder({
				fifteenMinutes: 3,
				oneSecond: 2,
				realTimeMetering: 1,
				oneHourTrending:4
			});

			window.realTimeMeteringTimeId = setInterval(() => {
				TrendingAnalysisAPI.getPortalRealTimeMetering(
					portalToken,
					selectedEquipment.location_node_id.split('.')[0]
					).then((response) => {
						if (response.data.data.status === 'OFFLINE') {
							clearInterval(window.realTimeMeteringTimeId)
							setIsPlotLoading(false)
							setRealTimeAlert({
								color: 'primary',
								show: true,
								text: 'Equipment is Not Cloud Connected',
							});
						}
						if (response.data.data.displayTimestamp !== null) {
							setTabData((prevState) => {
								return {
									...prevState,
									showPlot: true,
									plotData: response.data.data,
									isPlotLoading: isPlotLoading
								};
							});
							setIsPlotLoading(false)
						}
					}
					).catch(error => {
						clearInterval(window.realTimeMeteringTimeId)
						setIsPlotLoading(false)
						console.log(error)
					});
			}, 2000);
		}
	};

	return (
		<div>
			<Row>
				<Col>
					<h2 className='margin-top-10'>Real-Time Metering</h2>{' '}
				</Col>
			</Row>

			<Row>
				<Col className='col-2'>
					{isPlotLoading ? (
						<ReactLoading type='bars' height={50} width={50} color='#007bff' />
					) : (
						<Button variant='primary' onClick={onPlotButtonClick} className='plot_button'>
							Plot
						</Button>
					)}
				</Col>
				<Col className='col-6 trendingAlert'>
					<Alert
						variant={realTimeAlert.color}
						show={realTimeAlert.show}
						onClose={() => setRealTimeAlert({ ...realTimeAlert, show: false })}
						dismissible>
						{realTimeAlert.text}
					</Alert>
				</Col>
			</Row>
		</div>
	);
}

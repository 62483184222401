import { configureStore } from '@reduxjs/toolkit'
import equipmentsPortfolioSlice from './equipmentsPortfolioSlice/equipmentPortfolioSlice';
import voltageTypeSlice from './voltageTypeSlice/volatgeTypeSlice';
import tabStatusSlice from './tabStatusSlice/tabStatusSlice';
import userSlice from './userSlice/userSlice';

const store = configureStore({
  reducer: {
    equipmentsPortfolio: equipmentsPortfolioSlice.reducer,
    voltageType: voltageTypeSlice.reducer,
    tabStatus: tabStatusSlice.reducer,
    user: userSlice.reducer
  },
})

export default store;
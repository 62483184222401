import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useSelector } from 'react-redux';
import './NameplateTable.css';

const columns = [
	{ dataField: 'Name Plate', text: 'Name Plate' },
	{ dataField: 'Ratings', text: 'Ratings' },
];

const NamePlateTable = () => {
	const selectedEquipment = useSelector((state) => state.equipmentsPortfolio.selectedEquipment);

	let rows = [];

	if (selectedEquipment) {
		rows = [
			{ id: 0, 'Name Plate': 'Voltage (V)', Ratings: selectedEquipment.np_voltage },
			{ id: 1, 'Name Plate': 'Current (A)', Ratings: selectedEquipment.np_current },
			{ id: 2, 'Name Plate': 'Service Factor', Ratings: (selectedEquipment.np_sf == 0.1 ? '---' : selectedEquipment.np_sf) },
			{ id: 3, 'Name Plate': 'Frequency (Hz)', Ratings: selectedEquipment.np_frequency },
			{ id: 4, 'Name Plate': 'RPM', Ratings: selectedEquipment.np_rpm },
			{ id: 5, 'Name Plate': 'HP', Ratings: selectedEquipment.np_hp },
			{ id: 6, 'Name Plate': 'Starter', Ratings: selectedEquipment.starter },
			
		];
		if (selectedEquipment.belt_frequency !== '---') {
			
			rows.push({ id: 5, 'Name Plate': '', Ratings: '' });
			rows.push({ id:6 , 'Name Plate': 'Belt Frequency (Hz)', Ratings: selectedEquipment.belt_frequency });
		}
	}

	return (
		<div className='np-table'>
			{selectedEquipment ? <BootstrapTable keyField='id' data={rows} columns={columns} /> : null}
		</div>
	);
};

export default NamePlateTable;

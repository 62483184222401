import React, { createContext, useState } from 'react';

export const GlobalContext = createContext();

export function GlobalStore(props) {
	const [customerPortfolio, setCustomerPortfolio] = useState()
	const [customer, setCustomer] = useState([]);
	const [facilityList, setFacilityList] = useState([]);
	const [equipmentList = [], setEquipmentList] = useState();
	const [selectedFacility, setSelectedFacility] = useState();
	const [selectedEquipment, setSelctedEquipment] = useState();
	const [namePlateTableData, setNamePlateTableData] = useState([{ table_data: '' }]);
	const [namePlateTableColumns, setNamePlateTableColumns] = useState([{ columns: '' }]);
	const [minStartDate, setMinStartDate] = useState(new Date());
	const [timezone, setTimezone] = useState();
	const [selectedVoltageType, setSelectedVoltageType ] = useState({ label: 'Line to Line', value: 'L-L' })


	return (
		<GlobalContext.Provider
			value={[
				customerPortfolio,
				setCustomerPortfolio,
				customer,
				setCustomer,
				facilityList,
				setFacilityList,
				equipmentList,
				setEquipmentList,
				selectedFacility,
				setSelectedFacility,
				selectedEquipment,
				setSelctedEquipment,
				namePlateTableData,
				setNamePlateTableData,
				namePlateTableColumns,
				setNamePlateTableColumns,
				timezone,
				setTimezone,
				minStartDate,
				setMinStartDate,
				selectedVoltageType,
				setSelectedVoltageType,
			]}>
			{props.children}
		</GlobalContext.Provider>
	);
}

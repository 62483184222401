import React from 'react';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { equipmentsPortfolioActions } from '../../../../store/equipmentsPortfolioSlice/equipmentPortfolioSlice';

const SelectEquipment = () => {
	const { equipmentsList, selectedEquipment } = useSelector((state) => state.equipmentsPortfolio);

	const dispatch = useDispatch();

	const onSelectEquipment = (equipment) => {
		// Clear Selected list
		dispatch(equipmentsPortfolioActions.setSelectedEquipment(equipment));
	};

	return (
		<React.Fragment>
			<Select options={equipmentsList} value={selectedEquipment} onChange={(value) => onSelectEquipment(value)} />
		</React.Fragment>
	);
};

export default SelectEquipment;


const formatDate = (date) => {
	if (!date) {
	  return '---';
	} else {
	  const dateObj = new Date(date);
	  const formattedDate =
		dateObj.getUTCFullYear() +
		'-' +
		('0' + (dateObj.getUTCMonth() + 1)).slice(-2) +
		'-' +
		('0' + dateObj.getUTCDate()).slice(-2) +
		' ' +
		('0' + dateObj.getUTCHours()).slice(-2) +
		':' +
		('0' + dateObj.getUTCMinutes()).slice(-2) +
		':' +
		('0' + dateObj.getUTCSeconds()).slice(-2) +
		' UTC';
	  return formattedDate;
	}
};

class OverviewParser{
    static get alertColumns() {
        return [
            { dataField: 'time', text: 'Time', sort: true },
			{ dataField: 'subject', text: 'Alert', sort: true },
			//{ dataField: 'alert_sent_details', text: 'Sent By', sort: true },
        ]
    }

    static get plotLayout(){
        return {
            geo: {
                scope: 'north america',
                projection: {
                    //type: 'albers',
                    type: 'mercator',
                    scale: 0.55
                },
                showland: true,
                landcolor: '#f5f1f0',//'rgb(150,250,150)',

                showcountries: false,
                //countrycolor: 'rgb(50,50,50)',
                countrywidth: 0.25,

                showsubunits: true,
                subunitwidth: 0.25,
                //subunitcolor: 'black',

                showframe: true,
                //bgcolor: 'black',

                showocean: true,
                oceancolor: '#8cd0f3',//'#b4e2ee',

                showlakes: true,
                lakecolor: '#8cd0f3',//'#b4e2ee',

                resolution: '50',

                center:{
                    lat: 39,
                    lon: -94 
                },
                lonaxis: {
                    range: [-125,-65],
                },
                lataxis: {
                    range: [30,56]
                },
            },
            width: 3000,
            height: 800,
            margin: {
                autoexpand: false,
                l: 0,
                r: 0,
                t: 0,
                b: 0,
                pad: 0
            },
        }
    }

    static parsePlotData(facilitiesInfo, filteredAlerts, selectedFacility, facilityList){
        const availableFacilities = selectedFacility? [selectedFacility] : facilityList

        const traceTemplate = {
          type: "scattergeo",
          mode: "markers",
          hoverinfo: "text",
          hoverlabel:{
            font:{
                color: 'white'
            }
          },
          hovertext: [],
          name: [],
          lat: [],
          lon: [],
        };

        return facilitiesInfo?.reduce(
          (acc, f) => {
            if (!f) return acc;

            let trace = traceTemplate;

            if (
              availableFacilities
                .map((f) => f.facility_id)
                .includes(f["facility_id"])
            ) {
              if (f["Pre-Deployment"] > 0 || f["Unassigned"] > 0) {
                //Partially Deployed
                trace = acc[0];
              } else {
                //Fully Deployed
                trace = acc[1];
              }
            } else {
              //Unselected
              trace = acc[2];
            }

            const i = trace.name.length;
            const alertCount = filteredAlerts.filter(
              (a) => a.facility_id === f.facility_id
            ).length;

            trace["hovertext"].push(
                `\t\t${f["division"]}\t\t<br>` +
                `\t\t${f["facility_name"]}\t\t<br>` +
                `\t\tPre-Deployed Nodes: ${f["Pre-Deployment"]}\t\t<br>` +
                `\t\tDeployed Nodes: ${f["Deployed"]}\t\t<br>` +
                `\t\tUnassigned Nodes: ${f["Unassigned"]}\t\t<br>` +
                `\t\tAlerts: ${alertCount}\t\t\n`
            );
            trace["name"].push(f["facility_name"]);
            trace["lat"].push(parseFloat(f["facility_location"].split(",")[0]));
            trace["lon"].push(parseFloat(f["facility_location"].split(",")[1]));

            return acc;
          },
          [
            {
              //Partially Deployed Trace
              ...structuredClone(traceTemplate),
              marker: {
                size: 10,
                color: "#F4D03F",
                line: {
                  width: 1,
                },
              },
            },
            {
              //Fully Deployed Trace
              ...structuredClone(traceTemplate),
              marker: {
                size: 10,
                color: "green",
                line: {
                  width: 1,
                },
              },
            },
            {
              //Unselected Trace
              ...structuredClone(traceTemplate),
              marker: {
                size: 10,
                color: "gray",
                opacity: 0.4,
                line: {
                  width: 1,
                },
              },
            },
          ]
        );
    }

    static parseAlerts(alerts){
        
        for(var i in alerts){
            alerts[i].time = formatDate(alerts[i].time)
        }
        return alerts
    }

    static getFilteredAlerts(alerts, selectedFacility, facilityList, selectedEquipment, selectedAlertFilter){

        //Filter by left filter box
        const lfbAlerts = alerts.filter((alert) =>{
            if(selectedEquipment){
                return alert.location_node_id === selectedEquipment.location_node_id
            }else if(selectedFacility){
                return alert.facility_id === selectedFacility.facility_id
            }else{            
                const facilities = facilityList.map((f) => f.facility_id)
                return facilities.includes(alert.facility_id)
            }
        })

        //Filter by selected date
        const cutoff = new Date()
        const filterVal = selectedAlertFilter.value
        if(filterVal == 'day'){
            cutoff.setDate(cutoff.getDate() - 1)
        }else if( filterVal == 'week'){
            cutoff.setDate(cutoff.getDate() - 7)
        }else if(filterVal == 'month'){
            cutoff.setMonth(cutoff.getMonth() - 1)
        }else{
            cutoff.setUTCFullYear(0)
        }

        const filteredAlerts = lfbAlerts.filter((alert)=>{
            const alertDate = new Date(alert['time'])
            return cutoff < alertDate
        })

        return filteredAlerts
    }
}

export default OverviewParser
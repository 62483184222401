import portalAxios from '../axios/portalAxios'
class NotificationsSettingsApi {
	static getSubscriptions(token) {
		return portalAxios.get( 'api/v2/users/subscription', {
			headers: {
				
				Authorization: `Bearer ${token}`,
			},
		});
	}

	static patchSubscriptions(token, payload) {
		return portalAxios.patch('api/v2/users/subscription',
			{
				frequency: payload.frequency,
				facilities: payload.facilities
			}  ,
			{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	}
}

export default NotificationsSettingsApi
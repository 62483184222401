import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import SelectFacility from './components/SelectFacility/SelectFacility';
import SelectEquipment from './components/SelectEquipment/SelectEquipmet';
import SelectDivision from './components/SelectDivision/SelectDivision';
import NamePlateTable from './components/NameplateTable/NamePlateTable';
import ClearButton from './components/ClearButton/ClearButton';
import './LeftFilterBox.css';

const LeftFilterBox = () => {

	const customer = useSelector(state => state.equipmentsPortfolio.customerList[0].label)
	return (
		<div>
			<Card>
				<Card.Header>
					<Row className='justify-content-md-center'>
						<Col>
							<h4>{customer} </h4>
						</Col>
					</Row>
					<Row className='justify-content-md-center'>
						<Col>
							<text className='supportEmail'>For Support Contact: </text>
							<a href='mailto:support@voltainsite.com' className='supportEmail'>
								support@voltainsite.com
							</a>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body className='left'>
					{customer == 'Georgia Pacific'?
					<> 
					<Row>
						<Col>
							<label className='select_title'>Select Division :</label>
							<ClearButton />
							<br></br>
							<SelectDivision />
						</Col>
					</Row>
					<br></br>
					</>
					:
					<></>
					}
					<Row>
						<Col>
							<label className='select_title'>Select Facility :</label>
							{customer != 'Georgia Pacific'?<ClearButton /> : <></>}
							<br></br>
							<SelectFacility />
						</Col>
					</Row>
					<br></br>
					<Row>
						<Col>
							<label className='select_title'>Select Equipment :</label>
							<br></br>
							<SelectEquipment />
						</Col>
					</Row>
					<br></br>
					<Row className='justify-content-md-center'>
						<Col>
							<NamePlateTable />
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</div>
	);
};

export default LeftFilterBox;

import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import { equipmentsPortfolioActions } from '../../../../store/equipmentsPortfolioSlice/equipmentPortfolioSlice';
import './ClearButton.css';

const ClearButton = () => {
	const dispatch = useDispatch();

	const onClearButtonClick = () => {
		dispatch(equipmentsPortfolioActions.clearEquipmentPortfolio());
	};

	return (
		<React.Fragment>
			<Button variant='primary' className='clear-button' onClick={onClearButtonClick}>
				Clear
			</Button>
		</React.Fragment>
	);
};

export default ClearButton;

import authAxios from '../axios/authAxios';

class WaveformAnalysisAPI {
	static getEsaList(accessToken, location_node_id, date, timeZone, product_type) {
		return authAxios.get('/v1/common/get_esa_list/', {
			params: {
				location_node_id: `${location_node_id}`,
				start_date: `${date}`,
				end_date: `${date}`,
				timezone: `${timeZone}`,
				product_type: `${product_type}`,
			},
			headers: { Authorization: `Bearer ${accessToken}` },
		});
	}

	static getWaveformPlotData(
		accessToken,
		location_node_id,
		selectedEsa_value,
		duration,
		product_type
	) {
		return authAxios.get('/v1/waveform_analysis/esa_plot/', {
			params: {
				location_node_id: `${location_node_id}`,
				event_key: `${selectedEsa_value}`,
				plot_duration: `${duration}`,
				product_type: `${product_type}`,
			},
			headers: { Authorization: `Bearer ${accessToken}` },
		});
	}
}



export default WaveformAnalysisAPI;

import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
	earlyAccess: false,
};

const userSlice = createSlice({
	name: 'user',
	initialState: INITIAL_STATE,
	reducers: {
		setEarlyAccess(state, action) {
			return {
				...state,
				earlyAccess: action.payload,
			};
		},
	},
});

export const userActions = userSlice.actions;

export default userSlice;
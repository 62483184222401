import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import DateRangePicker from '../../../../DatePickers/DateRangePicker/DateRangePicker';
import Select from 'react-select';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';

import TrendingAnalysisAPI from '../../../../../api/TrendingAnalysisApi';

const plotParameters = [
	{ label: 'Voltage', value: 'Voltage' },
	{ label: 'Current', value: 'Current' },
	{ label: 'Line Frequency', value: 'Line Frequency' },
	{ label: 'Voltage Imbalance', value: 'Voltage Imbalance' },
	{ label: 'Current Imbalance', value: 'Current Imbalance' },
	{ label: 'Max Voltage Imbalance', value: 'Max Voltage Cycle Imbalance'},
	{ label: 'Max Current Imbalance', value: 'Max Current Cycle Imbalance' },
	{ label: 'Power Factor', value: 'Power Factor' },
	{ label: 'Power kW', value: 'Power kW' }
];

const formatDate = (date) => {
	return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
};

export default function OneHourTab({ tabData, setTabData, setPlotOrder }) {
	const token = Cookies.get('Session');

	// extarcting from redux slice
	const { selectedCustomer, selectedFacility, selectedEquipment, equipmentTimezone } = useSelector(
		(state) => state.equipmentsPortfolio
	);
	const selectedVoltageType = useSelector((state) => state.voltageType.selectedVoltageType);

	const [startDate, setStartDate] = useState(new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 7));
	const [endDate, setEndDate] = useState(new Date());

	const [plotLoading, setPlotLoading] = useState(false);
	const [alertBanner, setAlertBanner] = useState({
		color: '',
		show: false,
		text: '',
	});

	const [selectedPlotParameters, setSelectedPlotParameters] = useState([plotParameters[0], plotParameters[1]]);

	const plottedEquipment = useRef();
	const plottedVoltageType = useRef();

	useEffect(() => {
		setAlertBanner({ ...alertBanner, show: false });
	}, [selectedCustomer, selectedFacility, selectedEquipment]);

	useEffect(() => {
		if (selectedEquipment) {
			const equipStartDate = new Date(parseInt(selectedEquipment.equipment_start_epoch * 1000));

			// Reset Date Pickers
			if (equipStartDate > startDate) {
				const tempDate = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 7);
				if (equipStartDate > tempDate) setStartDate(equipStartDate);
				else setStartDate(tempDate);

				setEndDate(new Date());
			}
		}
	}, [selectedEquipment]);

	// Plot again when voltage type is changed
	useEffect(() => {
		if (selectedEquipment?.value == plottedEquipment.current && selectedVoltageType?.value != plottedVoltageType.current  && tabData.showPlot) {
			onPlotButtonClick();
		}
	}, [selectedVoltageType]);
	{
		/************************************ Tab Functions **************************************************************/
	}

	const onStartDateChange = (date) => {
		setStartDate(date);
	};

	const onEndDateChange = (date) => {
		setEndDate(date);
	};

	const onPlotButtonClick = () => {
		if (!selectedCustomer) {
			setAlertBanner({
				color: 'primary',
				show: true,
				text: 'Select a Customer',
			});
		} else if (!selectedFacility) {
			setAlertBanner({
				color: 'primary',
				show: true,
				text: 'Select a Facility',
			});
		} else if (!selectedEquipment) {
			setAlertBanner({
				color: 'primary',
				show: true,
				text: 'Select an Equipment ',
			});
		} else if (selectedPlotParameters.length == 0) {
			setAlertBanner({
				color: 'primary',
				show: true,
				text: 'Select at least one Parameter ',
			});
		} else {
			setAlertBanner({ ...alertBanner, show: false });
			setPlotLoading(true);
			setTabData((prevData) => {
				return {
					...prevData,
					plotMetaData: {
						selectedEquipment: selectedEquipment,
						startDate: formatDate(startDate),
						endDate: formatDate(endDate)
					},
				};
			});

			TrendingAnalysisAPI.getHourlyTrendData(
				token,
				selectedEquipment.location_node_id,
				formatDate(startDate),
				formatDate(endDate),
				equipmentTimezone.value,
				selectedPlotParameters
			)
				.then((response) => {
					setPlotLoading(false);
					if (response.status === 204) {
						setAlertBanner({
							color: 'primary',
							show: true,
							text: 'No Data Found',
						});
					} else {
						plottedEquipment.current = selectedEquipment.value;
						plottedVoltageType.current = selectedVoltageType.value;
						setTabData((prevData) => {
							return {
								...prevData,
								plotData: response.data.content.plot_data,
								tableData: response.data.content.table_data,
								showPlot: true,
							};
						});
						setPlotOrder({
							fifteenMinutes: 2,
							oneSecond: 4,
							realTimeMetering: 4,
							oneHourTrending:1
						});
						const plotDataArea = document.querySelector('.plotGraphOneHourArea');
						if (plotDataArea && tabData.showPlot) plotDataArea.scrollIntoView({ block: 'start',behavior: 'smooth' });
					}
				})
				.catch((error) => {
					setPlotLoading(false);
					console.log(error);
				});
		}
	};

	return (
		<React.Fragment>
			<Row>
				<Col>
					<h2 className='margin-top-10'>One Hour Trending</h2>{' '}
				</Col>
			</Row>
			<Row>
				<Col>
					<p>Select Date Range:</p>
				</Col>
			</Row>
			<Row>
				<Col className='minute-dateRange-input col-6'>
					<DateRangePicker
						startDate={startDate}
						endDate={endDate}
						onStartDateChange={onStartDateChange}
						onEndDateChange={onEndDateChange}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<br></br>
					<p>Select Parameters:</p>
					<Select
						isMulti
						isClearable
						defaultValue={[plotParameters[0], plotParameters[1]]}
						value={selectedPlotParameters}
						onChange={(value) => setSelectedPlotParameters(value)}
						options={plotParameters}
					/>
					<br></br>
				</Col>
			</Row>
			<Row>
				<Col className='col-2'>
					{plotLoading ? (
						<ReactLoading type='bars' height={50} width={50} color='#007bff' />
					) : (
						<Button variant='primary' onClick={onPlotButtonClick} className='plot_button'>
							Plot
						</Button>
					)}
				</Col>
				<Col className='col-4 trendingAlert'>
					<Alert
						variant={alertBanner.color}
						show={alertBanner.show}
						onClose={() => setAlertBanner({ ...alertBanner, show: false })}
						dismissible>
						{alertBanner.text}
					</Alert>
				</Col>
			</Row>
		</React.Fragment>
	);
}

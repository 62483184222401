import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
	portfolio: [],
	customerList:[{label: '',  value: ''}],
	divisionList: [],
	facilityList: [],
	equipmentsList: [],
	selectedDivision: null,
	selectedFacility: null,
	selectedEquipment: null,
	equipmentStartDate: new Date().toISOString(),
	showSELMenu: false,
	product_type: 'Node'
};

const equipmentsPortfolioSlice = createSlice({
	name: 'equipmentsPortfolio',
	initialState: INITIAL_STATE,
	reducers: {
		setEquipmentPortfolio(state, action) {
			const content = action.payload;
			return {
				...state,
				portfolio: content,
				customerList: content.customers,
				divisionList: content.divisions,
				facilityList: content.facilities,
				equipmentsList: content.equipments,
			};
		},

		setSelectedDivision(state, action) {
			const division = action.payload;

			//Filter the list based on selected division and customer
			const equipmentList = state.portfolio.equipments.filter((value) => {
				return value.division === division.label && value.customer === division.customer
			})
			const facilityList = state.portfolio.facilities.filter((value) => {
				return value.division === division.label && value.customer === division.customer
			});

			return {
				...state,
				equipmentsList: equipmentList,
				facilityList: facilityList,
				selectedCustomer: { value: equipmentList[0].customer, label: equipmentList[0].customer },
				selectedDivision: division,
				selectedFacility: null,
				selectedEquipment: null,
				equipmentTimezone: null,
				showSELMenu: false,
			};
		},

		setSelectedFacility(state, action) {
			const facility = action.payload;
			let equipmentTimezone ={ label: 'Equipment Time Zone', value: facility.timezone }

			// filter the list based on the selected facilty and customer
			const equipmentList = state.portfolio.equipments.filter((value) => {
				return value.facility_name === facility.label && value.customer === facility.customer;
			});
			const facilityList = state.portfolio.facilities.filter((value) => {
				return equipmentList[0].customer === value.customer && value.division == facility.division;
			});

			return {
				...state,
				equipmentsList: equipmentList,
				facilityList: facilityList,
				selectedCustomer: { value: equipmentList[0].customer, label: equipmentList[0].customer },
				selectedFacility: facility,
				selectedEquipment: null,
				selectedDivision: {
					value: facility.division,
					label: facility.division
				},
				equipmentTimezone: equipmentTimezone,
				showSELMenu: false,
			};
		},
		setSelectedEquipment(state, action) {
			const equipment = action.payload;
			const equipmentTimezone = { label: 'Equipment Time Zone', value: equipment.timezone };

			// filter the list based on the selected facilty and customer
			const equipmentList = state.portfolio.equipments.filter((value) => {
				return value.facility_name === equipment.facility_name && value.customer === equipment.customer;
			});

			const facilityList = state.portfolio.facilities.filter((value) => {
				return value.customer === equipment.customer;
			});

			return {
				...state,
				facilityList: facilityList,
				equipmentsList: equipmentList,
				selectedCustomer: { value: equipment.customer, label: equipment.customer },
				selectedFacility: {
					value: equipment.facility_name,
					label: equipment.facility_name,
					facility_id: equipment.facility_id,
					timezone: equipment.timezone,
				},
				selectedDivision:{
					value: equipment.division,
					label:  equipment.division
				},
				selectedEquipment: equipment,
				equipmentTimezone: equipmentTimezone,
				equipmentStartDate: new Date(parseInt(equipment.equipment_start_epoch * 1000)).toISOString(),
				showSELMenu: equipment.product_type === 'SEL' ? true : false,
				product_type: equipment.product_type
			};
		},
		clearEquipmentPortfolio(state) {
			return {
				...state,
				customerList: state.portfolio.customers,
				divisionList: state.portfolio.divisions,
				facilityList: state.portfolio.facilities,
				equipmentsList: state.portfolio.equipments,
				selectedCustomer: null,
				selectedDivision: null,
				selectedFacility: null,
				selectedEquipment: null,
				equipmentStartDate: new Date().toISOString(),
				showSELMenu: false,
				equipmentConnStatus:'ON'
			};
		},

	},
});

export const equipmentsPortfolioActions = equipmentsPortfolioSlice.actions;

export default equipmentsPortfolioSlice;
